:root {
  --font-family-sans-serif: "Montserrat";
}

$theme-colors: (
  "primary": #4064c1,
  "light": #fbfbfb
);

$border-radius: 15px;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
