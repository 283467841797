.progressSkills {
  margin-top: 2rem;
  pointer-events: none;
  h4 {
    text-align: left;
    color: #777;
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.5s;
  }
  .animation-bar-1 {
    position: relative;
    display: block;
    padding: 3px;
    font-size: 16px;
    line-height: 16px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
      0 1px rgba(255, 255, 255, 0.08);
    span {
      position: relative;
      display: block;
      height: 10px;
      border-radius: 10px;
      overflow: hidden;
      background: #4064c1;
      background-image: linear-gradient(to bottom, #4064c1, #2c4a96);
    }
  }
}

@keyframes dashSkill {
  0% {
    width: 0%;
  }
}
