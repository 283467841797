.masthead {
  text-align: center;
  color: #333333;
  background: rgb(232, 232, 232);
  background: linear-gradient(
    0deg,
    rgba(232, 232, 232, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  padding-top: 8rem;
  svg {
    margin-top: -5rem;
  }
  .intro-img {
    position: absolute;
    right: 0px;
    top: 0px;
    animation-name: introImg;
    animation-duration: 1s;
  }

  @keyframes introImg {
    0% {
      right: -20rem;
      top: 0px;
    }
    100% {
      right: 0px;
      top: 0px;
    }
  }

  .intro-text {
    padding-top: 100px;
    padding-bottom: 100px;
    .intro-lead-in {
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 25px;
      font-family: "Montserrat", sans-serif;
    }
    .intro-heading h1 {
      border-right: 0.15em solid #4064c1;
      white-space: nowrap;
      overflow: hidden;
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      font-family: "Montserrat", sans-serif;
      width: 8.3em;
      opacity: 0;
      -webkit-animation: type3 2s steps(20, end),
        blink 0.5s step-end infinite alternate;
      animation: type3 2s steps(20, end), blink 0.5s step-end infinite alternate;
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
  }
}

@media (max-width: 600px) {
  .masthead {
    padding: 0rem;
    background-position: 5px -19px;
    .intro-text {
      padding-top: 200px !important;
      .intro-lead-in {
        margin-bottom: 5px;
      }
      .intro-heading h1 {
        font-size: 35px;
        padding: 0px;
        margin: 5px 0px;
      }
    }
    .intro-img {
      margin-top: 50px;
      width: 55%;
      // position: absolute;
      // right: 0px;
      // top: 0px;
      // animation-name: introImg;
      // animation-duration: 1s;
    }
  }
}

@media (min-width: 768px) {
  .masthead {
    .intro-text {
      .subintro-lead-in {
        font-size: 30px;
        /* font-style: italic; */
        line-height: 40px;
        margin-bottom: 25px;
        font-family: "Montserrat", sans-serif;
      }
      .intro-lead-in {
        font-size: 35px;
        /* font-style: italic; */
        line-height: 40px;
        margin-bottom: 0px;
        font-family: "Montserrat", sans-serif;
      }
      p {
        padding-right: 9rem;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 2;
      }
      .intro-heading h1 {
        font-size: 65px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 0px;
      }
    }
  }

  .masthead .intro-text .masthead .intro-text .masthead .intro-img img {
    margin-top: 7rem;
    width: 25rem;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
