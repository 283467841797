#banner {
  color: #333333;
  background: #ededed;
  .row {
    justify-content: center;
    align-items: center;
    .border-right {
      border-right: 1px solid #626263 !important;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-right: 0.5rem;
        display: inline-block;
        a {
          font-size: 20px;
          line-height: 40px;
          display: block;
          width: 40px;
          height: 40px;
          transition: all 0.3s;
          color: white;
          border-radius: 100%;
          outline: none;
          background-color: #212529;
          text-align: center;
          &:hover {
            background-color: #4064c1;
          }
          svg {
            height: 1em;
            color: #fff;
            vertical-align: -0.12em;
          }
        }
      }
    }
  }
  #getInTouch {
    top: 12px;
    left: -165px;
    margin: 0;
    padding: 8px 16px;
    background: #4064c1;
    color: #fff;
    border-radius: 18px;
    box-shadow: 0 0 0 3px rgba(181, 202, 255, 0.5);
    animation: jump 1.5s ease-in-out infinite;
    &:hover {
      background-color: #212529;
      text-decoration: none;
    }
  }
}

@keyframes jump {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (max-width: 575.98px) {
  #banner {
    .row {
      .border-right {
        border-right: 0px !important;
        margin-bottom: 15px;
      }
    }
    #getInTouch {
      margin: 20px 0px;
    }
  }
}
