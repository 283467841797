#services {
  p {
    margin: 10px;
  }
  .servicesBox {
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
      display: flex;
      width: 200px;
      height: 200px;
      border: none;
      border-radius: 4px !important;
      margin: 10px;
      transition: 0.5s;
      cursor: pointer;
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
      }
      .card-body {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
          width: 4rem;
          height: 4rem;
          transition: 0.5s;
          // color: #777;
          color: #4064c1;
        }
        .card-title {
          margin-bottom: 0px;
          color: #777;
          // color: #4064c1;
          margin-top: 10px;
          font-weight: 700;
          font-size: 15px;
          letter-spacing: 1px;
          text-transform: uppercase;
          transition: 0.3s;
        }
        .card-text {
          font-size: 14px;
        }
        &:hover svg {
          width: 6rem;
          height: 6rem;
        }
        &:hover .card-title {
          font-size: 15px;
        }
      }
      &:hover {
        svg path {
          color: #4064c1;
        }
        .card-title {
          color: #4064c1;
        }
      }
    }
  }
}
