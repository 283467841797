.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s;

  .caption {
    margin-top: 10px;
    pointer-events: none;

    h4 {
      margin-bottom: 0px;
      color: #777;
      // color: #4064c1;
      margin-top: 10px;
      font-weight: 700;
      font-size: 15px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
    }
  }
  &:hover {
    transform: translateY(-10px);
    .caption {
      p {
        font-weight: 400;
      }
      h4 {
        color: #4064c1;
      }
    }
  }
}
