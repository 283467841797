.nav-link {
  font-size: 90%;
  // font-weight: 600;
  padding: 0.75em 0;
  letter-spacing: 1px;
  margin-left: 12px;
  margin-right: 12px;
  text-transform: uppercase !important;
  cursor: pointer;
}

.navbar-brand {
  cursor: pointer;
  top: -1rem;
  position: fixed;
  font-size: 1.75em;
  width: 8%;
  transition: all 0.3s;
  color: #4064c1;
  // font-weight: 600;
  img:hover {
    // display: none;
    transform: translateY(5px);
  }
}

ul.social-buttons {
  margin-bottom: 0;
  li a {
    font-size: 20px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    color: white;
    border-radius: 100%;
    outline: none;
    background-color: #212529;
    text-align: center;
  }
}

ul.social-buttons ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #4064c1;
}

@media (min-width: 992px) {
  .navbar {
    padding-top: 0px;
    padding-bottom: 8px;
    // transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    padding-top: 8px;
    // background-color: transparent;
    .navbar-nav {
      padding-left: 15%;
      .nav-item .nav-link {
        padding: 1.1em 1em !important;
        .active {
          color: #4064c1;
        }
      }
    }
    .navbar-shrink {
      padding-top: 0;
      padding-bottom: 0;
      background-color: #212529;
      .navbar-brand {
        font-size: 1.25em;
        /* padding: 12px 0; */
        color: white !important;
      }
    }
  }
}

@media (min-width: 1400px) {
  .navbar .navbar-brand {
    width: 8%;
    left: 21%;
    top: -2rem;
  }
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active {
  opacity: 1;
  transition: opacity 600ms linear;
}

@media (max-width: 575.98px) {
  .navbar-brand {
    width: 20%;
    top: -0.8rem;
  }
}
