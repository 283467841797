#skillsGraphs {
  position: relative;
  display: flex;
  justify-content: space-around;
  //   align-items: center;
  //   min-height: center;
  //   background: #101010;
  .card {
    position: relative;
    width: 250px;
    // background: linear-gradient(0deg, #1b1b1b, #222, #1b1b1b);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
    &:hover {
      transform: translateY(-10px);
      // box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -50%;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.03);
      pointer-events: none;
      z-index: 1;
    }
    .percent {
      position: relative;
      width: 94px;
      height: 94px;
      border-radius: 50%;
      // box-shadow: inset 0 0 50px #000;
      // background: #222;
      z-index: 1000;
      .number {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        h2 {
          color: #777;
          font-weight: 700;
          font-size: 20px;
          transition: 0.5s;
          span {
            font-size: 14px;
            color: #777;
          }
        }
      }
    }
    .text {
      position: relative;
      color: #777;
      margin-top: 10px;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      transition: 0.5s;
      pointer-events: none;
    }
  }

  .card:hover .text {
    color: black;
  }

  .card:hover .percent .number h2 {
    color: black;
    font-size: 30px;
    span {
      color: black;
      transition: 0.5s;
    }
  }
  svg {
    position: relative;
    width: 100px;
    height: 100px;
    z-index: 1000;
    circle {
      width: 100%;
      height: 100%;
      fill: none;
      // stroke: #191919;
      stroke-width: 10;
      stroke-linecap: round;
      transform: translate(5px, 5px);
      &:nth-child(2) {
        stroke-dasharray: 250;
        // stroke-dashoffset: 250;
      }
    }
  }
  .card:nth-child(1) svg circle:nth-child(2) {
    stroke: #00ff43;
  }
  .card:nth-child(2) svg circle:nth-child(2) {
    stroke: #00a1ff;
  }
  .card:nth-child(3) svg circle:nth-child(2) {
    stroke: #ff04f7;
  }
  .card:nth-child(4) svg circle:nth-child(2) {
    stroke: #fbff02;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 250;
  }
}

@media (max-width: 575.98px) {
  #skillsGraphs {
    flex-direction: column-reverse;
    align-items: center;
  }
}
