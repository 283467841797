.cog3 {
  width: 6rem;
  height: 6rem;
  position: relative;
}

.cog4 {
  width: 5rem;
  height: 5rem;
  position: relative;
  top: -1rem;
  left: -1rem;
}
