.timeline {
  position: relative;
  margin: 50px auto;
  width: 100%;
  padding: 40px 0;
  box-sizing: border-box;
  pointer-events: none;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 2px;
    height: 330px;
    animation: dashVLine 2s ease-in-out forwards;
    background: #4064c1;
  }
  .content {
    padding-bottom: 20px;
    h3 {
      font-size: 16px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
    }
  }
  .time h4 {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      line-height: normal;
      position: relative;
      width: 50%;
      padding: 10px 40px;
      list-style: none;
      box-sizing: border-box;
      &:nth-child(odd) {
        float: left;
        text-align: right;
        clear: both;
        // border-right: 2px solid;
        &:before {
          content: "";
          position: absolute;
          width: 10px;
          top: 24px;
          right: -6px;
          height: 10px;
          background: #4064c1;
          border-radius: 50%;
          // box-shadow: 0 0 0 3px rgba(181, 202, 255, 0);
        }
        .time {
          position: absolute;
          top: 12px;
          right: -165px;
          margin: 0;
          padding: 8px 16px;
          background: #4064c1;
          color: #fff;
          border-radius: 18px;
          box-shadow: 0 0 0 3px rgba(181, 202, 255, 0.5);

          // box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.3);
        }
      }
      &:nth-child(even) {
        float: right;
        text-align: left;
        clear: both;
        &:before {
          content: "";
          position: absolute;
          width: 10px;
          top: 24px;
          left: -4px;
          height: 10px;
          background: #4064c1;
          border-radius: 50%;
          box-shadow: 0 0 0 3px rgba(181, 202, 255, 0.5);
        }
        .time {
          position: absolute;
          top: 12px;
          left: -165px;
          margin: 0;
          padding: 8px 16px;
          background: #4064c1;
          color: #fff;
          border-radius: 18px;
          box-shadow: 0 0 0 3px rgba(181, 202, 255, 0.5);
        }
      }
    }
  }
}

@keyframes dashVLine {
  0% {
    height: 0px;
  }
}

@media (max-width: 575.98px) {
  .timeline {
    &:before {
      height: 27rem;
    }
    ul li {
      padding: 10px;
      &:nth-child(even) {
        // display: none;
        .time {
          left: -110px;
        }
      }
      &:nth-child(odd) {
        // display: none;
        .time {
          right: -135px;
        }
      }
      // &:nth-child(odd) {
      //   // display: none;
      //   left: -110px;
      // }
    }
  }
}
