@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 600px) {
  #portfolio {
    margin-top: 0px !important;
    padding-top: 50px;
  }

  #navbarResponsive {
    margin-left: 0px;
  }

  #mainNav .navbar-nav .nav-item .nav-link {
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  .ml-small {
    margin-left: 2.5rem;
  }

  #mainNav .navbar-brand {
    width: 28%;
    top: -1rem;
  }

  #mainNav .navbar-toggler {
    margin-left: 18rem;
  }
}

.btn {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
}

.btn-xl {
  font-size: 18px;
  padding: 20px 40px;
}

.btn-primary {
  background-color: #4064c1;
  border-color: #4064c1;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #212529 !important;
  border-color: #4064c1 !important;
  color: white;
}

.btn-primary:active,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
}
